import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { ValidNetwork, infuraUrl } from './utils/constants';

const projectId = '36d5257c909b4eac17a6afd92d38b2b5'

const mainnet = {
  chainId: ValidNetwork,
  name: 'mainnet',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io/',
  rpcUrl: infuraUrl
}

const metadata = {
  name: 'Basset',
  description: 'Basset',
  url: 'https://www.dapp.basset.bet',
  icons: ['https://avatars.mywebsite.com/']
}

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: false,
})

export const modal = createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
