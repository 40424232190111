import React from 'react';
import { classNames } from '../utils/helpers';
import spinner from '../assets/spinner.gif'
const Button = ({ type, title, onClick, isLoading = false }) => {
  return (
    <button 
      onClick={onClick}
      disabled={isLoading}
      className={classNames({
      'btn btn-primary': type === 'primary',
      'btn btn-secondary': type === 'secondary',
    }) + ' flex justify-center disabled:cursor-not-allowed'}>
        {isLoading ? <img width={30} src={spinner} alt='loading'/> : title}
    </button>
  )
}

export default Button