import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import  Logo from '../assets/Logo-Iniciox400.png';

const DisabledModal = ({ show, close }) => {
  return (
    <>
      <AnimatePresence>
        {show && (
          <>
            <motion.div
              onClick={close}
              className='fixed z-40 w-screen h-screen inset-0 bg-black bg-opacity-70 '
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
<div className='flex justify-center items-start overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
  <div className='relative w-full md:w-auto my-6 mx-auto max-w-3xl'>
    <motion.div
      className='border-0 rounded-lg relative flex flex-col w-full bg-[#150f35] outline-none focus:outline-none p-6 md:p-10'
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}>
      <div className='flex flex-col gap-6 items-center'>
        
        <div className='flex justify-center items-center'>
          <img src={Logo} alt="Next Phase" className='w-full h-full object-cover' />
        </div>
       
        <h2 className='text-2xl text-center font-bold text-white'>We will back soon with the next phase</h2>
      </div>
    </motion.div>
  </div>
</div>



          </>
        )}
      </AnimatePresence>
    </>  )
}

export default DisabledModal