import './App.css';
import { useEffect, useState } from 'react';
import Button from './components/Button';
import Input from './components/Input';
import OptionButton from './components/OptionButton';
import { ethers, parseUnits } from 'ethers'
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { USDC_ADDRESS, USDT_ADDRESS, TOKEN_ADDRESS } from './utils/constants';
import { ValidNetwork, infuraUrl } from './utils/constants';
import usdcAbi from './data/usdc_abi.json'
import token_abi from './data/token_abi.json'
import { modal } from './index'
import { parseAddress, parseToken } from './utils/helpers';
import { formatUnits } from 'ethers';
import DisabledModal from './components/DisabledModal';



function App() {
  const { open, close } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [tokenAmount, setTokenAmount] = useState(0);
  const [tokenContract, setTokenContract] = useState(undefined);
  const [usdcContract, setUsdcContract] = useState(undefined);
  const [usdtContract, setUsdtContract] = useState(undefined);
  const [isApproved, setIsApproved] = useState(false);
  const [balance, setBalace] = useState(0);
  const [tokenReceive, setTokenReceive] = useState(false);
  const [selected, setSelected] = useState('usdc');
  const [allowance, setAllowance] = useState(0);
  const [input, setInput] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [coinDecimals, setCoinDecimals] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [actualPhase, setActualPhase] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [saleStatus, setSaleStatus] = useState(true);
  const [actualNetwork, setActualNetwork] = useState(0);

  modal.subscribeState(newState => setIsOpen(newState.open))
  
  const getMax = async () => {
    if(!isConnected || isOpen) return;
    const contract = selected === 'usdc' ? usdcContract : usdtContract;
    const decimals = await contract.decimals();
    const balance = await contract.balanceOf(address);
    let parsed = formatUnits(balance, decimals);
    setInput(parsed)
    const tokenBalance = await tokenContract.getTokenAmount(USDC_ADDRESS, balance)
    setTokenReceive(parseToken(tokenBalance[0]));
    setInput(formatUnits(tokenBalance[1],6));
    console.log(balance, allowance, tokenBalance[1]);
    if (balance <= allowance) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }
  }

  const connect = async () => {
    await open();
  };

  const handleChange = async (e) => {
    setInput(e.target.value);

    if(e.target.value !== '' || e.target.value > 0){

      if(!coinDecimals) return;

      const parsedInput = parseUnits(e.target.value, coinDecimals);
      const balance = await tokenContract.getTokenAmount(USDC_ADDRESS, parsedInput);
      setTokenReceive(formatUnits(balance[0], 6));
      setInput(formatUnits(balance[1],6));
      if (parsedInput <= allowance) {
        setIsApproved(true);
      } else {
        setIsApproved(false);
      }
    } else {
      setTokenReceive(0);
    }
  };

  const approveToken = async () => {
    try {
      setIsLoading(true)
      const contract = selected === 'usdc' ? usdcContract : usdtContract;
      //const balance = await contract.balanceOf(address);
      const balance = parseUnits(input, 6);
      let response = await contract.approve(tokenContract, balance);
      await response.wait();
      await getAllowance();
      setIsApproved(true);
    }
    catch(err){
      console.log(err)
    }
    finally{
      setIsLoading(false);
    }
  }

  const getBalance = async () => {
    if (usdcContract === undefined || usdtContract === undefined)
    return;
    const contract = selected === 'usdc' ? usdcContract : usdtContract;
    const balance = await contract.balanceOf(address);
    setBalace(formatUnits(balance,6))
  }

  const buyToken = async () => {
    try {
      if(input === 0 || input === '') return;
      setIsLoading(true);
      const parsedInput = parseUnits(input, coinDecimals);
      const tx = await tokenContract.bassetFinance(USDC_ADDRESS, parsedInput);
      await tx.wait();
      setInput(0)
      setTokenReceive(0)
      await init()
    }
    catch(err){
      console.log(err)
    }
    finally{
      setIsLoading(false);
    }
  }

  const start = async () => {
    const ethersProvider = new ethers.getDefaultProvider(infuraUrl);
    const contract = new ethers.Contract(TOKEN_ADDRESS, token_abi, ethersProvider);
    const saleStatus = await contract.saleStatus();
    const phase = await contract.phase();
    const price = await contract.phasePrice(phase);
    setTotalSold(formatUnits(await contract.totalSold(), 6));
    setActualPhase(Number(phase)+ 1);
    setTokenPrice(formatUnits(price, 6));
    setSaleStatus(saleStatus);
    const intervalId = setInterval(() => { 
      try {
        contract.saleStatus().then((status) => setSaleStatus(status));
      } catch(e) {console.log(e)}
    }, 10000);
  }


  const init = async () => {
    try {
      if(!isConnected || isOpen) return;

      const ethersProvider = new ethers.BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const network = await ethersProvider.getNetwork();

      setActualNetwork(network.chainId);

      if (network.chainId !== ValidNetwork) {
        return;
      }

      let contract;
      let usdt;
      let usdc;
      if (tokenContract === undefined) {
        contract = new ethers.Contract(TOKEN_ADDRESS, token_abi, signer);
        setTokenContract(contract);
      } else {
        contract = tokenContract;
      }

      if (usdcContract === undefined) {
        usdc = new ethers.Contract(USDC_ADDRESS, usdcAbi, signer);
        setUsdcContract(usdc);
      } else {
        usdc = usdcContract;
      }

      if (usdtContract === undefined) {
        usdt = new ethers.Contract(USDT_ADDRESS, usdcAbi, signer);
        setUsdtContract(usdt);
      } else {
        usdt = usdtContract;
      }



      const status = await contract.saleStatus();
      console.log(tokenContract, status);
      const balanceToken = await contract.buyersAmount(address);
      const phase = await contract.phase();
      const price = await contract.phasePrice(phase);
      const decimals = await usdc.decimals();

      setActualPhase(Number(phase)+ 1);
      setTokenPrice(formatUnits(price, 6));
      setTokenAmount(formatUnits(balanceToken[0], decimals));
      
      setTotalSold(formatUnits(await contract.totalSold(), 6))
      setSaleStatus(status);

    } catch (error) {
      console.error(error);
    }
  };

  const getAllowance = async () => {
    if (usdcContract === undefined || usdtContract === undefined)
      return;
    const contract = selected === 'usdc' ? usdcContract : usdtContract;
    const response = await contract.allowance(address, tokenContract);
    const decimals = await contract.decimals();
    setCoinDecimals(decimals);
    setAllowance(response);
  };

  useEffect(() => {
    getAllowance();
    getBalance();
  }, [usdcContract, usdtContract])


  useEffect(() => {
    start();
  },[]);

  useEffect(() => {
    if(!isConnected || isOpen) return;
    init();
  }, [isConnected, isOpen])

  useEffect(() => {
    setInput(0);
    if(!isConnected || isOpen) return;
    getAllowance();
    getBalance();
  }, [selected])

  return (
    <>
      <DisabledModal show={!saleStatus}/>
      <div className='max-w-xl mx-auto border my-auto border-black p-4 flex flex-col gap-8 mt-10'>
        <div className='flex gap-4'>
          <OptionButton title={'USDC'} icon={'usdc'} selected={selected === 'usdc'} onClick={() => setSelected('usdc')} />
          <OptionButton title={'USDT'} icon={'usdt'} selected={selected === 'usdt'} onClick={() => setSelected('usdt')} />
        </div>
        <div className='w-full border' />
        <div className='flex gap-4'>
          <Input label={`Use ${selected.toUpperCase()} (${balance})`} max={true} onClick={getMax} onChange={handleChange} icon={selected} value={input} />
          <Input label='Receive TOKEN' disabled={true} icon={'token'} value={tokenReceive} />
        </div>
        <div className='text-center text-gray-400 font-semibold'>
          <div className='flex justify-between'>
            <span>Current price (USDC/USDT):<b className='text-gray-700'> {tokenPrice}</b></span>
            <span>Actual phase: <b className='text-gray-700'> {parseInt(actualPhase)}</b></span>
          </div>
          <br />
          <div className='flex justify-between'>
            <span>Total sold: <b className='text-gray-700'> {totalSold}</b></span>
            <span>You own {tokenAmount} $BASSET</span>
          </div>
        </div>
        {isConnected && isApproved ? <Button type='primary' title='Buy now' onClick={buyToken} isLoading={isLoading} /> :
        <Button type={isConnected ? 'primary' : 'secondary'} title='Approve' onClick={approveToken} isLoading={isLoading || !isConnected} />}
        <Button title={isConnected ? (actualNetwork === ValidNetwork? parseAddress(address): 'Wrong network, switch to etherum mainnet') : 'Connect Wallet'} 
                type={isConnected ? 'secondary' : 'primary'} 
                onClick={connect}/>
      </div>
    </>
  );
}

export default App;
