import React from 'react';
import { ReactComponent as UsdtIcon } from '../assets/usdt-icon.svg';
import { ReactComponent as UsdcIcon } from '../assets/usdc-icon.svg';
import { ReactComponent as TokenIcon } from '../assets/token-icon.svg';
import {toHexString} from 'ethers'

const Input = ({ label, disabled, max, icon, onClick, onChange, value}) => {
    // console.log(toHexString(value))
    const formattedValue = parseFloat(value.toString().replace(',', '.')); // Convertir la cadena "0,100" a 0.100
    const icons = {
        usdc:  <UsdcIcon className='h-8 w-8'/>,
        usdt: <UsdtIcon className='h-8 w-8' />,
        token: <TokenIcon className='h-8 w-8' />
      };
  return (
    <>  
        <div className='flex flex-col gap-1'>
            <div className='flex justify-between'>
                <label className='font-semibold text-gray-700'>{label}</label>
                {max &&
                    <button className='text-blue-700 font-semibold' onClick={onClick}>MAX</button>
                }
            </div>
            <div className='flex gap-4 border-gray-300 items-center rounded-md border-1 px-4'>
                <input className='w-full outline-none h-12 p-2' 
                    placeholder='0' 
                    type='number' 
                    onChange={onChange}
                    value={formattedValue}
                    step=".0001"
                    disabled={disabled}
                 />
                <div className='h-8 w-8'>
                  {icons[icon]}
                </div>
            </div>
        </div>
    </>
  )
}

export default Input