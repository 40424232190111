import { formatUnits } from 'ethers'
function classNames(classes) {
    const toBeClasses = Object.keys(classes).map((key) =>
      classes[key] === true ? key : '',
    );
    return toBeClasses.join(' ');
}

function parseAddress(address) {
  return address.slice(0,6) + '...' + address.slice(address.length - 6)
}

function parseToken(quantity) {
  return formatUnits(quantity, 6)
}
export { classNames, parseAddress, parseToken }