import React from 'react'
import { ReactComponent as UsdtIcon } from '../assets/usdt-icon.svg';
import { ReactComponent as UsdcIcon } from '../assets/usdc-icon.svg';
import { ReactComponent as TokenIcon } from '../assets/token-icon.svg';

const OptionButton = ({ title, icon, selected, onClick }) => {
  const icons = {
    usdc:  <UsdcIcon className='h-8 w-8'/>,
    usdt: <UsdtIcon className='h-8 w-8' />,
    token: <TokenIcon className='h-8 w-8' />
  };
  return (
    <button 
        onClick={onClick}
        className={`h-12 w-full gap-2 flex items-center justify-center 
                   border-1 rounded-md ${selected ? 'border-gray-400' : 'border-gray-300' }`}>
        {/* <img src={icons[icon]} className='w-10 h-10' /> */}
        {icons[icon]}
        {title}
    </button>  
  )
}

export default OptionButton